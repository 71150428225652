/*
 * CTOExpertiseTagsHandler
 */
var CTOExpertiseTagsHandler = {
    init: function (params) {
        this.containerElementID = params.containerElementID; //"#onboarding-category-form"
        this.updatePending = false;
        this.initDOMReady();
        // check if the csrf is still needed?
        /*
        $.ajaxSetup({
            headers: {
                "X-CSRF-TOKEN": "php echo csrf_token();"
            }
        });
        */
    },
    checkPostInit: function () {
        if (typeof toSmartJSLoader !== "undefined") {
            var deferInit = toSmartJSLoader.getPostInitObj("CTOExpertiseTagsHandler");
            if (deferInit) {
                this.init(deferInit);
            }
        }
    },
    initDOMReady: function () {
        var _this = this;
        $(document).ready(function () {
            if ($(_this.containerElementID).length === 0) {
                return;
            }
            //console.log("CTOExpertiseTagsHandler.initDOMReady");
            _this.initErrors();
            _this.initTemplates();
            _this.initButtons();
            _this.initElements();
        });
    },
    initErrors: function () {
        this.errors = toGlobalStateContainer.TAGMANAGER.errorss;
        if (this.errors == undefined) this.errors = {};
    },
    initTemplates: function () {
        var labels = toGlobalStateContainer.TAGMANAGER.labels;
        this.addTagHTML = '<li class="add-tag"><div class="btn btn-add-tag">' + labels.addTag + '</div></li>';
        this.tagElementHTML = '<li class="tag" data-tag=""><span class="tag-text"></span><i autocomplete="off" class="remove la-cross btn-remove-tag"></i></li>';
        this.tagInputHTML = '<li class="tag"><input type="text" name="tags[]" pattern="[A-Za-z0-9 ]" placeholder="' + labels.tagType + '" value="" autocomplete="off" /><i class="remove la-cross btn-remove-tag"></i></li>';
        this.tagSearchHTML = '<div class="tagsearch"><ul class="list-unstyled"></ul></div>';
        this.addAnotherTagHTML = '<li class="add-another-tag"><div class="btn btn-add-another">+ ' + labels.addAnother + '</div></li>';
        this.expertiseProvidePrefix = labels.helpPrefix;
        this.errorHTML = '<div class="error tag-error" style="font-size:12px;"></div>';
    },
    initElements: function () {
        var _this = this;
        $(this.containerElementID).on("blur", ".tag input:not([readonly])", function(e) {
            var input = $(this);
            if (input.data("blur-before-remove")) {
                // remove will be called separately
            } else {
                _this.removeTagSearch(input);
            }
        });
        $(this.containerElementID).on("keydown", ".tag input:not([readonly])", function(e) {
            if (e.which === 13) {
                // enter pressed
                e.preventDefault();
                _this.tagDone($(this));
            }
        });
        $(this.containerElementID).on("keypress", ".tag input", function(e) {
            if (e.which === 13) {
                e.preventDefault();
            }
        });
        $(this.containerElementID).on("keyup", ".tag input", function(e) {
            if (e.which === 13) {
                e.preventDefault();
            }
        });
        $(this.containerElementID + " ul.tags").on("mousedown", ".tag-suggestion", function () {
            // note: this has to be mousedown, not click, otherwise the input blur would fire before this
            //          and that would actually completely prevent this event handler to fire
            // set the value of the input to the suggestion
            var tagListItem = $(this).parents(".tag")
            var tagInput = $(tagListItem).find("input");
            tagInput.val($(this).data("prediction"));
            // and save it
            _this.tagDone(tagInput);
        });
    },
    initButtons: function () {
        var _this = this;
        $(this.containerElementID + " ul.tags").on("click", ".btn-add-tag", function () {
            _this.addTagInputToList($(this).parents("ul.tags"));
            $(this).parent().remove();
        });
        $(this.containerElementID + " ul.tags").on("click", ".btn-remove-tag", function () {
            var tagListItem = $(this).parents(".tag")
            tagListItem.append('<span class="spinner"><img src="https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/blocks.svg"></span>')
            tagListItem.find(".remove").hide();
            //console.log("call remove, ", $(this).parent()[0], $(this).closest(".expertise-category").data("category"));
            _this.removeTag($(this).parent().data("tag"), $(this).closest(".expertise-category").data("category"));
        });
        $(this.containerElementID + " ul.tags").on("click", ".btn-add-another", function () {
            _this.addTagInputToList($(this).parents("ul.tags"));
            $(this).parent().remove();
        });
    },
    addTagElementToList: function (taglist, tag) {
        var newElem, tagInput;
        tagInput = taglist.find("input");
        tagInput.removeAttr("readonly");
        /* // removing the input - this will be done by the blur event handler
        if (tagInput.length) { // remove the tag input if still there
            tagInput.parents(".tag").first().remove();
        }
        /* - */
        // problem: we want to remove the tag search input -> this.removeTagSearch(tagInput);
        //      but, it has a (delegated) blur event handler
        //      -> removing the input will trigger it, and fail as the element is already removed...
        //      triggering the blur seems to somehow still fail, not clear why // tagInput.trigger("blur");
        //          maybe because tagInput here is a reference to the input which would be removed through the event handler
        // workaround: trigger the blur but make sure the tagInput is not removed there
        //      and then remove it from here (so it gets removed but blur will not be triggered again by the removal)
        tagInput.data("blur-before-remove", true);
        tagInput.trigger("blur");
        this.removeTagSearch(tagInput);
        taglist.find(".add-tag , .add-another-tag").remove(); // remove the add button if still there
        taglist.append(this.tagElementHTML); // add the new tag elem
        newElem = taglist.find(".tag").last();
        newElem.data("tag", tag).attr("data-tag", tag.toLowerCase());
        newElem.find(".tag-text").text(tag.toLowerCase());
    },
    addTagInputToList: function (taglist) {
        var taglistItem, newInput;
        taglist.append(this.tagInputHTML);
        taglistItem = taglist.find(".tag").last();
        taglistItem.find(".remove").hide();
        newInput = taglistItem.find("input");
        newInput.focus();
        this.initTagSearchInput(newInput);
    },
    showTagSuggestions: function (predictions, status, inputElement) {
        var suggestionList = $(inputElement).siblings(".tagsearch").find("ul");
        suggestionList.html("").hide();
        if (predictions.length > 0) {
            $(suggestionList).show();

            for (var i = 0; i < predictions.length; i++) {
                if (predictions[i] != undefined) {
                    $(suggestionList).append('<li class="tag-suggestion" data-prediction="' + predictions[i] + '">' + predictions[i] + '</li>');
                }
            }
        }
    },
    initTagSearchInput: function (element) {
        var _this = this;
        $(element).after(this.tagSearchHTML);
        $(element).on("focusout", function(e) {
            $(element).siblings(".tagsearch").remove();
        });
        //this.showTagSuggestions([], true, element); // ???
        $(element).on("keyup", function(e) {
            var inputfield = this;
            if ($(this).val() == undefined || $(this).val() == "") {
                _this.showTagSuggestions([], true, $(inputfield));
            } else {
                if (_this.tagSearchTimeoutID) {
                    clearTimeout(_this.tagSearchTimeoutID);
                }
                _this.tagSearchTimeoutID = setTimeout(function () {
                    var inputObject = new Object();
                    inputObject.input = $(inputfield).val();
                    inputObject.expertiseId = $(inputfield).closest(".expertise-category").data("category");
                    _this.findTags(inputObject, _this.showTagSuggestions, $(inputfield));
                }, 100);
            }
        });
    },
    findTags: function (searchObject, callBack, inputField) {
        $.ajax({
            contentType: "application/json",
            data: JSON.stringify(searchObject),
            dataType: "json",
            success: function(data){
                if (data.success) {
                    callBack(data.data, true, inputField);
                } else {
                    console.log("no success finding tags:" + data.error);
                }
            },
            error: function(){
                console.log("no success finding tags: connection error");
            },
            processData: false,
            type: "POST",
            url: theone.url("/api/find-tags")
        });
    },
    updateTag: function (tag, expertiseId, url) {
        var _this = this;
        $.ajax({
            type: "POST",
            url: url,
            data: { tag:tag, expertiseId:expertiseId, _token: toGlobalStateContainer.CSRF },
            dataType: "json",
            success: function(data) {
                //console.log(url, "update tag success " , data);
                _this.updatePending = false;
                _this.tagUpdated(data);
                // check if profile was just completed
                if (data.user_became_expert && typeof CTOAjaxModalHandler !== "undefined") {
                    CTOAjaxModalHandler.loadModal("/ajax/user-became-expert");
                }
            },
            error: function(err) {
                _this.updatePending = false;
                console.log(url, "update tag error: connection error", err);
            },
        });
    },
    saveTag: function (tag, expertiseId) {
        this.updateTag(tag, expertiseId, theone.url('/' + theone.session.content_locale + "/settings/expertise/save-tag"));
    },
    removeTag: function (tag, expertiseId) {
        this.updateTag(tag, expertiseId, theone.url('/' + theone.session.content_locale + "/settings/expertise/remove-tag"));
    },
    tagUpdated: function (data) {
        console.log("tagUpdated", data);
        var expertiseDiv = $("#expertise-category-" + data.expertise);
        var taglist = expertiseDiv.find("ul.tags");
        var errorElement = $(this.containerElementID + " .tag-error");
        if (errorElement.length) {
            errorElement.remove();
        }
        if (data.success) {
            if (data.action === "removed") {
                expertiseDiv.find('li[data-tag="' + data.tag + '"]').remove();
            } else if (data.action === "saved") {
                this.addTagElementToList(taglist, data.tag);
            }
            this.checkForEmptyList(taglist);
            // add the new add button if needed
            if (taglist.find(".add-another-tag").length === 0 && taglist.find(".tag").length < toGlobalStateContainer.TAGMANAGER.maxTags && taglist.find(".tag").length > 0) {
                taglist.append(this.addAnotherTagHTML);
            }
        } else {
            console.log("update error", data);
            var msg = this.errors.GENERIC;
            if (data.error_code && this.errors[data.error_code]) {
                msg = this.errors[data.error_code];
            }
            expertiseDiv.append(this.errorHTML);
            errorElement = expertiseDiv.find(".tag-error");
            errorElement.html(msg);
            taglist.find("input").trigger("blur");
        }
    },
    removeTagSearch: function (inputField) {
        var taglist;
        if (this.updatePending) {
            inputField.attr("readonly", "readonly");
            return;
        }
        taglist = inputField.parents("ul.tags");
        inputField.parent().remove();
        taglist.append(this.addAnotherTagHTML); // add the button back to the list before checking for empty
        this.checkForEmptyList(taglist);
    },
    tagDone: function (inputField) {
        var taglistItem;
        if (inputField.val() === undefined || inputField.val() === "") {
            //this.removeTagSearch(inputField);
        } else {
            taglistItem = inputField.parents("li");
            taglistItem.append('<span class="spinner"><img src="https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/blocks.svg"></span>');
            //taglistItem.find(".btn").hide();
            this.updatePending = true;
            this.saveTag(inputField.val(), inputField.closest(".expertise-category").data("category"));
        }
    },
    checkForEmptyList: function (taglist) {
        var expertiseDiv = $(taglist).closest(".expertise-category");
        var expertiseDescription = expertiseDiv.find(".description");
        if (taglist.find(".tag").length) {
            expertiseDiv.addClass("selected");
        } else {
            expertiseDiv.removeClass("selected");
            taglist.html(this.addTagHTML);
            var description = expertiseDescription.text();
            if (description.startsWith(this.expertiseProvidePrefix)) {
                description = description.substring(this.expertiseProvidePrefix.length, description.length).trim();
                description = description.charAt(0).toUpperCase() + description.slice(1);
                expertiseDescription.text(description);
            }
        }
    },
};
CTOExpertiseTagsHandler.checkPostInit();

/*
 * CTOExpertCertificateTagsHandler
 */
var CTOExpertCertificateTagsHandler = {
    init: function (params) {
        this._containerElementSelector = params.containerElementSelector;
        this._updatePending = false;
        this.initDOMReady();
    },
    checkPostInit: function () {
        if (typeof toSmartJSLoader !== "undefined") {
            var deferInit = toSmartJSLoader.getPostInitObj("CTOExpertCertificateTagsHandler");
            if (deferInit) {
                this.init(deferInit);
            }
        }
    },
    initDOMReady: function () {
        var _this = this;
        $(document).ready(function () {
            if ($(_this._containerElementSelector).length === 0) {
                return;
            }
            console.log("CTOExpertCertificateTagsHandler.initDOMReady");
            _this.initExpertTagElements();
            _this.initExpertTagButtons();
        });
    },
    initExpertTagElements: function () {
        var _this = this;
        var inputs = $(this._containerElementSelector + " input");
        this.expertTagFindTimeoutID = false;
        inputs.on("keyup", function () {
            var query = $(this).val();
            var certificateId = $(this).parents("ul").first().data("cert-id")
            if (_this.expertTagFindTimeoutID) {
                clearTimeout(_this.expertTagFindTimeoutID);
            }
            if (String(query).length) {
                _this.expertTagFindTimeoutID = setTimeout(function () {
                    _this.findExpertTags(query, certificateId, _this.expertTagSuggestions);
                }, 200);
            }
        });
        inputs.on("blur", function () {
            var tagList = $(this).parents("ul").first();
            $(this).parents("li").first().hide();
            tagList.find(".add-another-tag").show();
        });
        inputs.on("keydown", function (e) {
            if (e.which === 13) {
                var expertCertificateId = $(this).parents(_this._containerElementSelector).first().data("cert-id");
                var tagList = $(this).parents("ul");
                tagList.append('<li class="loader"><span class="spinner"><img src="https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/blocks.svg"></span></li>');
                _this.saveExpertTag($(this).val(), expertCertificateId, _this.expertTagSaved);
            }
        });
        $(".expert-tag-suggestions").on("mousedown", "li", function () {
            var expertCertificateId = $(this).parents(_this._containerElementSelector).first().data("cert-id");
            var tag = $(this).text();
            var tagList = $(this).parents("ul");
            tagList.append('<li class="loader"><span class="spinner"><img src="https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/blocks.svg"></span></li>');
            _this.saveExpertTag(tag, expertCertificateId, _this.expertTagSaved);
        });
    },
    initExpertTagButtons: function () {
        var _this = this;
        var containerElem = $(this._containerElementSelector);
        containerElem.on("click", ".btn", function () {
            //console.log("this should be the add", $(this).parents("ul").first().data("cert-id"));
            var tagList = $(this).parents("ul").first();
            $(this).parents("li").first().hide();
            tagList.find(".expert-tag-input").show();
            tagList.find(".expert-tag-input input").focus();
        });
        containerElem.on("click", ".remove", function () {
            var tagList = $(this).parents("ul").first();
            // this can be the tag input or a tag
            if ($(this).data("tag-id")) {
                var tagListItem = $(this).parents(".tag")
                tagListItem.append('<span class="spinner"><img src="https://calltheone-space.ams3.cdn.digitaloceanspaces.com/public/images/blocks.svg"></span>')
                 _this.deleteExpertTag($(this).data("tag-id"), $(this).data("cert-id"), _this.expertTagDeleted);
                //console.log("this should be the remove", $(this).data("tag-id"));
            } else {
                $(this).parent().hide();
                tagList.find(".add-another-tag").show();
            }
        });
    },
    expertTagDeleted: function (data) {
        var removeBtn = $(this._containerElementSelector + " [data-tag-id=" + data.deleted_tag_id + "]");
        if (removeBtn.length) {
            removeBtn.parent().remove();
        }
    },
    expertTagSaved: function (data, certificateId) {
        //console.log("expertTagSaved", certificateId, data);
        var tagList = $(this._containerElementSelector + "[data-cert-id=" + certificateId + "]");
        if (data.tag_added) {
            // tag was new, added, add element
            var addExpertTagElement = tagList.find(".add-another-tag").first();
            $('<li class="tag"><span class="tag-text">' + data.tag_added + '</span><i autocomplete="off" class="remove la-cross" data-tag-id="' + data.tag_id + '"></i></li>').insertBefore(addExpertTagElement);
        } else {
            // tag was already there
        }
        // reset input / suggestions
        tagList.find(".loader").remove();
        tagList.find(".add-another-tag").show();
        tagList.find(".expert-tag-input").hide();
        tagList.find("input").val("");
        $(".expert-tag-suggestions").html("");
    },
    expertTagSuggestions: function (tags, certificateId) {
        // get this certificate's suggestion list element
        var tagList = $(this._containerElementSelector + "[data-cert-id=" + certificateId + "]");
        var suggestionList = tagList.find("ul");
        var resultHTML = "";
        for (var x in tags) {
            resultHTML += '<li>' + tags[x] + '</li>';
        }
        suggestionList.html(resultHTML);
    },
    callApi: function (url, data, callBack) {
        var _this = this;
        if (this._updatePending) {
            return;
        }
        this._updatePending = true;
        $.ajax({
            contentType: "application/json",
            data: data,
            dataType: "json",
            success: function(data){
                _this._updatePending = false;
                if (data.success) {
                    callBack(data.data);
                } else {
                    console.log("no success API (" + url + "):" + data.error);
                }
            },
            error: function(){
                _this._updatePending = false;
                console.log("no success API (" + url + "): connection error");
            },
            processData: false,
            type: "POST",
            url: url
        });
    },
    findExpertTags: function (query, certificateId, callBack) {
        var _this = this;
        var data = JSON.stringify({
            query: query,
            expert_certificate_id: certificateId
        });
        this.callApi("/api/find-expert-tags", data, function (responseData) {
            callBack.call(_this, responseData, certificateId);
        });
    },
    saveExpertTag: function (tag, certificateId, callBack) {
        var _this = this;
        var data = JSON.stringify({
            tag: tag,
            expert_certificate_id: certificateId
        });
        this.callApi("/api/post-expert-tag", data, function (responseData) {
            callBack.call(_this, responseData, certificateId);
        });
    },
    deleteExpertTag: function (tag_id, certificateId, callBack) {
        var _this = this;
        var data = JSON.stringify({ tag_id: tag_id, expert_certificate_id: certificateId });
        this.callApi("/api/delete-expert-tag", data, function (responseData) {
            callBack.call(_this, responseData);
        });
    },
};
CTOExpertCertificateTagsHandler.checkPostInit();

