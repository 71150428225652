var CTODatePicker = {
	init: function () {
		var _this = this;
		//console.log("CTODatePicker.init");
		this._domInit = false;
		this._datePickerSelectors = [];
		$(document).ready(function () {
			_this.domReady();
		});
	},
	domReady: function () {
		this._domInit = true;
		for (var i = 0; i < this._datePickerSelectors.length; i++) {
			this.initPicker(this._datePickerSelectors[i]);
		}
	},
	initPicker: function (selector) {
		var _this = this;
		$(selector.inputSelector).daterangepicker({
			autoUpdateInput: false,
			drops: "up",
			singleDatePicker: true,
			showDropdowns: true,
			alwaysShowCalendars: true,
			locale: {
				format: "DD-MM-YY"
			},
			startDate: moment().format("DD-MM-YY"),
			minDate: moment().format("DD-MM-YY"),
			applyClass: "btn-primary",
			showCustomRangeLabel: false
		});
		if (selector._shouldUpdateTimezone) {
			$(selector.inputSelector).on("apply.daterangepicker", function(ev, picker) {
				$(this).val(picker.startDate.format("DD-MM-YY"));
				$(selector.valueSelector).val(picker.startDate.format("YYYY-MM-DD"));
				_this.updateAvailableTimes(selector);
				_this.timezoneHandleTimeChange(selector);
			});
			// make time selector also update the timezone value
			$(".selectpicker").on("hidden.bs.select", function () {
				console.log("time change");
				_this.timezoneHandleTimeChange(selector);
			});
		} else {
			$(selector.inputSelector).on("apply.daterangepicker", function(ev, picker) {
				$(this).val(picker.startDate.format("DD-MM-YY"));
				$(selector.valueSelector).val(picker.startDate.format("YYYY-MM-DD"));
				_this.updateAvailableTimes(selector);
			});
		}
		this.limitTimeToNow($(selector.timeInput));
	},
	timezoneHandleTimeChange: function (selector) {
		var dt = $(selector.valueSelector).val();
		var tm = $(selector.timeInput).val();
		var tz = $(selector.tzSelector).text();
		var datetime = dt + (tm.length ? " " + tm : "");
		if (dt.length) {
			if (tm.length) {
				$(selector.tzValue).text(moment(datetime).tz(tz).format("YYYY-MM-DD HH:mm"));
			} else {
				$(selector.tzValue).text(moment(datetime).tz(tz).format("YYYY-MM-DD"));
			}
		}
	},
	limitTimeToNow: function (timeInputElement) {
		var now = moment().format("HH:mm");
		var firstValid = 0;
		timeInputElement.find("option").each(function (idx) {
			this.disabled = (this.value < now);
			if (!this.disabled && !firstValid) {
				firstValid = idx;
			}
		});
		if (timeInputElement.prop("selectedIndex") > 0 && timeInputElement.prop("selectedIndex") <= firstValid) {
			timeInputElement.prop("selectedIndex", firstValid);
		}
		timeInputElement.selectpicker("render");
	},
	updateAvailableTimes: function (selector) {
		var start, end, startIndex, endIndex, now = moment();
		if (selector.timeInput) {
			start = $(selector.timeInput);
			start.find("option").prop("disabled", false);
			if (now.format("DD-MM-YY") === $(selector.inputSelector).val()) {
				this.limitTimeToNow(start);
			} else {
				start.selectpicker("render");
			}
			startIndex = start.prop("selectedIndex");
			if (selector.timeEndInput) {
				end = $(selector.timeEndInput); // "[name=preferred_between_end]"
				endIndex = end.prop("selectedIndex");
				end.find("option").prop("disabled", false);
				end.find("option").slice(0, startIndex + 1).prop("disabled", true);
				if (endIndex <= startIndex + 1) {
					end.prop("selectedIndex", startIndex + 1);
				}
				end.selectpicker("render");
			}
		}
	},
	forceRegisterDatePicker: function (inputSelector, valueSelector, timeInput, tzSelector, tzValue) {
		for (var x in this._datePickerSelectors) {
			if (this._datePickerSelectors[x].inputSelector === inputSelector) {
				delete this._datePickerSelectors[x].inputSelector;
				break;
			}
		}
		this.registerDatePicker(inputSelector, valueSelector, timeInput, tzSelector, tzValue);
	},
	registerDatePicker: function (inputSelector, valueSelector, timeInput, tzSelector, tzValue) {
		var selectorObj = { inputSelector:inputSelector, valueSelector:valueSelector, timeInput:timeInput, tzSelector:tzSelector, tzValue:tzValue };
		for (var x in this._datePickerSelectors) {
			if (this._datePickerSelectors[x].inputSelector === inputSelector) {
				return;
			}
		}
		selectorObj._shouldUpdateTimezone = (selectorObj.tzSelector && selectorObj.tzValue && $(selectorObj.tzSelector).length && $(selectorObj.tzValue).length);
		this._datePickerSelectors.push(selectorObj);
		if (this._domInit) {
			// domReady already done - init this now
			this.initPicker(selectorObj);
		}
	},
};
CTODatePicker.init();