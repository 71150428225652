/* used in requests */
$(function() {
	function updateTimelist() {
		var localTimezone = moment.tz.guess();
		var localRegion = localTimezone.split("/")[1];
		setTimeLeftString($('.time-until-start .h1'),theone.timelistProvider.start);
		setTimeStringForTz($('.traveler-time'),localTimezone);
		$('.traveler-location').html(localRegion);
		if(theone.timelistProvider.timezone) {
			var destinationTimezone = theone.timelistProvider.timezone;
			var destinationRegion = theone.timelistProvider.timezoneCity;
			if (isNaN(parseInt(destinationTimezone))) {
				setTimeStringForTz($('.guide-time'),destinationTimezone);
				$('.guide-location').html(destinationRegion.replace("_"," "));
			} else {
				var offset = parseInt(destinationTimezone);
				var hours = offset / 60;
				setTimeStringForOffset($('.guide-time'),offset);
				$('.guide-location').html('UTC' + (hours >= 0 ? "+" : "") + hours);
			}
		} else {
			$('.guide-time').html('');
			$('.guide-location').html('');
		}
	    var t = setTimeout(updateTimelist, 500);
	}
	updateTimelist();

	function setTimeLeftString(element,dt) {
	    $(element).html(moment().to(dt).replace('in ',''));

		//console.log(dt);
	}
	function setTimeStringForTz(element,tz) {
		var	time = moment().tz(tz);
		setTimeString(element,time);
	}
	function setTimeStringForOffset(element,offset) {
		var time = moment().utcOffset(offset);
		setTimeString(element,time);
	}
	function setTimeString(element,time) {
	    $(element).html(time.format('HH:mm'));
	}
});