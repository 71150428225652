/*
 * toVideoStreamController
 *      DEPENDS ON: moment js
 */
/* // ?? this class is in call.opentok.js - maybe started to move it here?
var toVideoStreamController = {
    init: function (params) {
        // params?
        //this._sessionId = params.sessionId;
    },
    connectionLost: function () {
        this._connectionAlive = false;
        //this.showConnectionLostModal();
    },
    connectionRestored: function () {
        this._connectionAlive = true;
        this.lastConnectionRestoredTime = moment();
        //this.hideConnectionLostModal();
    },
};
toVideoStreamController.init({});
*/



/*
 * toCallPermissionsHelper
 * to handle testing video call permissions
 */
var toCallPermissionsHelper = {
    init: function() {
        //logger("toCallPermissionsHelper.init()");
        var _this = this;
        _this.canCall = false;
    },
    testCallSupport: function() {
        var result = OT.checkSystemRequirements();
        if (result && showSuccess) {
            toModalHelper.showAlert("Your browser supports video and audio TheONE","Video Support");
        } else {
            OT.upgradeSystemRequirements();
        }
    },
    testNativeAudio: function() {    
        
        var constraints = {
            audio:true,
            video:true
        };


        navigator.mediaDevices.getDisplayMedia(constraints)
        .then(
            function(mediaStream) {
                console.log("Accepted",mediaStream);
                console.log(mediaStream.getAudioTracks());
            },
            function(rejectValue) {
                console.log("Rejected",rejectValue);
            }
        )
        .catch(
            function(error) {
                console.log("Error",error); 
                return null;
            }
        );
        
        //toModalHelper.showAlert("Audio is allowed");
    },
    needPermissionsCheck: function() {
        var result = navigator.permissions == undefined;
        console.log("needPermissionsCheck",result);
        return result;
    },
    silentPermissionCheck: function(onSuccess, onFailure, onNeedsPrompt) {
        console.log("silentPermissionCheck");
        var _this = this;
        try {
            if (!navigator.permissions) {
                // fallback to media
                this.checkCallPermissionsWithMedia(onSuccess, onFailure, true);
                return;
            }
            navigator.permissions.query({name:"camera"}).then(function(result) {
                if (result.state == "granted") {
                    navigator.permissions.query({name:"microphone"}).then(function(result) {
                        if (result.state == "granted") {
                           if (onSuccess != undefined) onSuccess();
                        } else if (result.state == "prompt") {
                           if (onNeedsPrompt != undefined) onNeedsPrompt();
                        } else {
                           if (onFailure != undefined) onFailure({error:"microphone",result:result});
                        }
                    });
                } else if (result.state == "prompt") {
                   if (onNeedsPrompt != undefined) onNeedsPrompt();
                } else {
                   if (onFailure != undefined) onFailure({error:"camera",result:result});
                }
            })
            .catch(function () {
                // most likely because permissions.query({name:'camera'}) is not supported by the browser
                // continue to check with getUserMedia
                _this.checkCallPermissionsWithMedia(onSuccess, onFailure, true);
            });
        } catch (e) {
            // most likely because permissions.query({name:'camera'}) is not supported by the browser
            // continue to check with getUserMedia
            this.checkCallPermissionsWithMedia(onSuccess, onFailure, true);
        }

    },
    checkCallPermissionsWithMedia: function(onSuccess, onFailure, useCallbacks) {
        var message = '';

        var result = OT.checkSystemRequirements();

        if (!result) {
            message = Lang.get('tours.videocalls_error_opentok');
            if (useCallbacks) {
                onFailure({error:message});
            } else {
                toModalHelper.showAlert(message,Lang.get('tours.videocalls_error'));
            }
        } else if (navigator.mediaDevices == undefined) {
            message = Lang.get('tours.videocalls_error_security');
            if (useCallbacks) {
                onFailure({error:message});
            } else {
                toModalHelper.showAlert(message,Lang.get('tours.videocalls_error'));
            }
        } else {
            navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(function (userMedia) {
                var mediaTracks = userMedia.getTracks();
                /* // ??
                for (var i = 0; i < mediaTracks.length; i++) {
                    mediaTracks[i].stop();
                }
                */
                if (!mediaTracks || !mediaTracks.length) {
                    // this _probably_ means access not allowed...
                    if (useCallbacks) {
                        onFailure({error:"mediaTracks"});
                    }
                }
                if (theone.browser == 'Safari' || theone.browser == 'Firefox') {
                    if (useCallbacks) {
                        onSuccess();
                    } else {
                        toModalHelper.showAlert(Lang.get('tours.videocalls_browser_ok_always',{browser:theone.browser}),Lang.get('tours.videocalls_ok'),onSuccess);
                    }
                } else {
                    if (useCallbacks) {
                        onSuccess();
                    } else {
                        toModalHelper.showAlert( Lang.get('tours.videocalls_browser_ok',{browser:theone.browser}),Lang.get('tours.videocalls_ok'),onSuccess);
                    }
                }
            }).catch(function (error) {
                var failureError = error;
                toCallPermissionsHelper._lastMediaError = error;
                toCallPermissionsHelper._lastMediaErrorName = error.name;

                switch (error.name) {
                    case 'NotAllowedError':
                    case 'PermissionDeniedError':
                        message = Lang.get('tours.videocalls_error_disallowed', { browser: theone.browser } );
                        break;
                    case 'AbortError':
                    case 'NotReadableError':
                        message = Lang.get('tours.videocalls_error_hardware');
                        break;
                    case 'SecurityError':
                        message = Lang.get('tours.videocalls_error_security');
                        break;
                    case 'NotFoundError':
                    case 'DevicesNotFoundError':
                    case 'OverconstrainedError':
                    case 'TypeError':
                    case 'SourceUnavailableError':
                    default:
                        // this _can_ be because not allowed...
                        //message = error.message; // this is not human readable
                        message = Lang.get('tours.videocalls_error_disallowed', { browser: theone.browser } );
                        failureError = {error: message};
                }

                if (useCallbacks) {
                    onFailure(failureError);
                } else {
                    toModalHelper.showAlert(message,Lang.get('tours.videocalls_error'),onFailure);
                    console.log("toCallPermissionsHelper.checkCallPermissionsWithMedia() getUserMedia error: ", error);
                }
            });
        }
    },
    checkCallPermissions: function(onSuccess,onFailure,permissionsChecked) {
        var _this = this;
        try {
            if (!navigator.permissions) {
                // fallback to media
                this.checkCallPermissionsWithMedia(onSuccess, onFailure, false);
                return;
            }
            if (permissionsChecked == undefined) {
                navigator.permissions.query({name:'camera'}).then(function(result) {
                    if (result.state == 'granted' || result.state == 'prompt') {
                        navigator.permissions.query({name:'microphone'}).then(function(result) {
                            if (result.state == 'granted' || result.state == 'prompt') {
                                // still check media too?
                                //_this.checkCallPermissions(onSuccess,onFailure,true);
                                _this.checkCallPermissionsWithMedia(onSuccess, onFailure, false);
                            } else {
                                _this.checkCallPermissions(onSuccess,onFailure,false);
                            }
                        });
                    } else {
                        _this.checkCallPermissions(onSuccess,onFailure,false);
                    }
                })
                .catch(function () {
                    // most likely because permissions.query({name:'camera'}) is not supported by the browser
                    // continue to check with getUserMedia
                    _this.checkCallPermissionsWithMedia(onSuccess, onFailure, false);
                });
                return;
            } else if (!permissionsChecked) {
                toModalHelper.showAlert(Lang.get('tours.videocalls_error_disallowed'),Lang.get('tours.videocalls_error'),onFailure);
                return;
            }
        } catch (e) {
            // most likely because permissions.query({name:'camera'}) is not supported by the browser
            // continue to check with getUserMedia
            this.checkCallPermissionsWithMedia(onSuccess, onFailure, false);
        }
    }
}

/*
 * toNotificationHandler
 * to handle permission and showing local notification
 */
var toNotificationHandler = {
    init: function() {
        logger('toNotificationHandler.init()');
        var _this = this;
        _this.notificationIcon = "/images/logo_square.png";
        _this.canNotify = false;
        _this.initCanNotify();
    },    
    initCanNotify: function() {
        var _this = this;
        _this.checkPermission(function() {
            _this.canNotify = true;            
        });
    },
    warnAboutNoPermission: function() {
        var _this = this;
        toModalHelper.showAlert(Lang.get('tours.notifications_disallow',{url:theone.url('')}));
    },
    warnAboutPermission: function() {
        var _this = this;
        _this.checkPermission(null,null,function() {
            toModalHelper.showConfirm(Lang.get('tours.notifications_allow'),
                Lang.get('tours.notifications_title'),
                function () {
                    _this.requestPermission(function() { _this.initCanNotify() });
                }
            );
        })
    },
    checkPermission: function(onPermission,onNoPermission,onUnknownPermission) {
        if (!("Notification" in window)) { 
            if(onNoPermission != undefined) onNoPermission();
        } else if (Notification.permission === "granted") {
            console.log("Notification.permission granted",Notification.permission);
            if(onPermission != undefined) onPermission();
        } else if (Notification.permission !== "denied") {
            console.log("Notification.permission not denied",Notification.permission);
            if (onUnknownPermission != undefined) onUnknownPermission();
        }
    },
    requestPermission: function(onDone) {
        if("Notification" in window) {
            Notification.requestPermission(function (permission) {
                if (onDone != undefined) onDone();
            });
        } else {
            if (onDone != undefined) onDone();
        }
    },
    askForPermission: function() {
        var _this = this;
        _this.checkPermission(function() {
            ;
            toModalHelper.showAlert(Lang.get('tours.notifications_allowed'));
        },function() {
            toModalHelper.showAlert(Lang.get('tours.notifications_disallowed'));
        },function() {
            toModalHelper.showConfirm(Lang.get('tours.notifications_confirm'),
                Lang.get('tours.notifications_title'),
                function () {
                    _this.requestPermission(function() { _this.initCanNotify() });
                }
            );

        });

    },
    askForNoPermission: function() {
        var _this = this;
        _this.checkPermission(function() {
            toModalHelper.showAlert(Lang.get('tours.notifications_revoke', {url:theone.url('')}));
        },function() {
            toModalHelper.showAlert(Lang.get('tours.notifications_reinstate'));
        });
    },
    notify: function(title, message, repeat) {
        if (repeat == undefined) repeat = 0;
        var _this = this;
        
        if (!_this.canNotify) return;
        _this.stopNotifying();
        var notification = new Notification(title, { body: message, icon: _this.notificationIcon, tag: notificationTag});
        notification.onclick = function() {
            // browser notification clicked by user
            if (typeof toCallHandler !== "undefined" && toCallHandler.incomingCallData && toCallHandler.incomingCallData.session_id) {
                toCallHandler.saveNotificationStatus(theone.App_InstantCall_WEB_NOTIF_CLICKED, toCallHandler.incomingCallData.session_id);
            }
            _this.stopNotifying();
        }
        notification.onclose = function() {
            // browser notification clicked by user
            if (typeof toCallHandler !== "undefined" && toCallHandler.incomingCallData && toCallHandler.incomingCallData.session_id) {
                toCallHandler.saveNotificationStatus(theone.App_InstantCall_WEB_NOTIF_CLICKED, toCallHandler.incomingCallData.session_id);
            }
            _this.stopNotifying();
        }


        if (repeat > 0) {
            var notificationTag = "tag_" + (new Date()).getTime();
            var repeat = repeat > 0 ? Math.max(repeat,10) : 0;
            var repeated = 0;
            _this.notificationInterval = setInterval(function () {
                if (repeated < repeat) {
                    notification = new Notification(title, { body: message, icon: _this.notificationIcon, tag: notificationTag, renotify: true});
                    notification.onclick = function() {
                        _this.stopNotifying();
                    }
                    notification.onclose = function() {
                        _this.stopNotifying();
                    }
                } else {
                    _this.stopNotifying();
                }
                repeated++;
            },4000);
        }
    },
    stopNotifying: function() {
        console.log("stopNotifying()");
        var _this = this;
        if (_this.notificationInterval != undefined) {
            clearInterval(_this.notificationInterval);
            _this.notificationInterval = null;
        }
    },
}
toNotificationHandler.init();

/*
 * toOpentokSessionHandler
 *      DEPENDS ON: toNotificationHandler, opentok js, moment js, toVideoStreamController
 *
 *  at the moment this is only used for opentok messaging / in the call modals
 *      TODO: this should replace all JS in the call view too - opentok.blade.php
 */
var toOpentokSessionHandler = {
    init: function (params) {
        // params?
        this._connectionCount = 0;
        this._globalConnectionData = null;
        this._isPublisher = false;
        // "constants"
        this.MESSAGE_TYPE = {
            DECLINE:            "DCL",
            ACCEPT:             "ACC",
            HANGUP:             "HNG",
            LATER:              "LTR",
            CANCEL:             "CNL",

            /*
            TEXT:               "TXT",
            BLOCK:              "BLK",
            NO_FUNDS:           "FND",
            NO_ONE_THERE:       "NOT",
            GIVE_ALTERNATIVE:   "ALT",
            FLIP_TO_APP:        "FTA",
            FLIP_TO_VIDEO:      "FTV",
            */
        };
    },
    initSessionData: function (sessionId, apiKey, token) {
        this._sessionId = sessionId;
        this._apiKey = apiKey;
        this._token = token;
    },
    connect: function () {
        var _this = this;
        this._session = OT.initSession(this._apiKey, this._sessionId);
        this._session.on({
            sessionReconnecting: function(event) {
                console.log("toOpentokSessionHandler / session.sessionReconnecting");
                // apparently some browser (firefox?) starts with this event, even before toVideoStreamController is ready
                // -> in which case it's not even a "real" reconnecting, so just skip the connectionLost
                if (typeof toVideoStreamController !== "undefined") {
                    toVideoStreamController.connectionLost();
                }
            },
            sessionReconnected: function(event) {
                console.log("toOpentokSessionHandler / session.sessionReconnected");
                // apparently some browser (firefox?) starts with this event, even before toVideoStreamController is ready
                // -> in which case it's not even a "real" reconnecting, so just skip the connectionRestored
                if (typeof toVideoStreamController !== "undefined") {
                    toVideoStreamController.connectionRestored();
                }
                _this.setOtherConnection(event.connection);
            },
            sessionDisconnected: function(event) {
                console.log("toOpentokSessionHandler / session.sessionDisconnected " , event.reason);
                //logSessionEvent('sessionDisconnected', event.connection ? event.connection.data : globalConnectionData);
                if (event.reason === "clientDisconnected" && toVideoStreamController.lastConnectionRestoredTime && moment().diff(toVideoStreamController.lastConnectionRestoredTime) < 5000) {
                    // sometimes this happens after a succesful reconnect, try to make a new connection
                    _this.handleConnectionDestroyed(); // apparently session.connectionDestroyed is not called in this case, fake it
                    setTimeout(function () {
                        //_this.initPublisher();
                        _this.makeConnection();
                    }, 1000);
                }
            },
            connectionCreated: function (event) {
                _this._connectionCount++;
                console.log("toOpentokSessionHandler / session.connectionCreated ", _this._connectionCount);
                // connection metadata is: event.connection.data (see: https://tokbox.com/developer/sdks/js/reference/Connection.html)
                // make an async api call to the lookalong app to save the event with the data
                if (event.connection.connectionId === _this._session.connection.connectionId) {
                    _this._globalConnectionData = event.connection.data;
                    //logSessionEvent('selfConnectionCreated', event.connection.data);
                } else {
                    //logSessionEvent('otherConnectionCreated', event.connection.data);
                    //console.log("Another client connected. " + _this._connectionCount + " total.");
                }

                if (_this._connectionCount > 1 || _this._signalMessageSource === theone.App_InstantCall_SOURCE_WEB) {
                    // note: if the message was coming from a web client, send the signal anyway
                    //       otherwise the _connectionCount > 1 should indicate the source app is also connected to the session
                    _this.canSignal();
                }
                _this.setOtherConnection(event.connection);
            },
            connectionDestroyed: function (event) {
                _this.handleConnectionDestroyed(event);
            },
            streamCreated: function(event) {
                console.log("toOpentokSessionHandler / new stream in the session: " + event.stream.streamId);
                _this.setOtherConnection(event.connection);
                //console.log(event);
                // do API call to server
            },
            streamDestroyed: function (event) {
                // streamDestroyed
            },
        });
        // handlers are in place, make connection
        this.makeConnection();
    },
    setOtherConnection: function (connection) {
        if (connection) {
            console.log("event connection id: ", connection.connectionId);
        } else {
            console.log("no event connection");
        }
        if (this._session && this._session.connection) {
            console.log("session connection id: ", this._session.connection.connectionId);
        } else {
            console.log("no session connection");
        }
        if (connection && this._session && this._session.connection) {
            if (this._session.connection.connectionId !== connection.connectionId) {
                this._otherConnection = connection;
            }
        }
    },
    handleConnectionDestroyed: function (event) {
        this._connectionCount--;
        console.log("toOpentokSessionHandler.handleConnectionDestroyed / a client disconnected, total: " + this._connectionCount);
        if (event) {
            if (event.connection.connectionId === this._session.connection.connectionId) {
                //logSessionEvent('selfConnectionDestroyed', event.connection.data);
            } else {
                //logSessionEvent('otherConnectionDestroyed', event.connection.data);
            }
        }
        if (this._connectionCount <= 1) {
            //pauseTimer();
            toVideoStreamController.connectionAlive = false;
        }
    },
    makeConnection: function () {
        var _this = this;
        this._otherConnection = null;
        //logSessionEvent("makeConnection() called", "");
        this._session.connect(this._token, function (error) {
            if (error) {
                if (error.name === "OT_NOT_CONNECTED") {
                    //logSessionEvent("session.connect() error", "{ msg: 'OT_NOT_CONNECTED' }");
                    console.log("Failed to connect. Please check your connection and try connecting again.");
                } else {
                    //logSessionEvent("session.connect() error", "{ msg: '" + error.name + "' }");
                    console.log("An unknown error occurred connecting. Please try again later.");
                }
            } else if (_this.isPublisher) {
                //_this.connected();
                console.log("makeConnection done, publisher");
                //start streaming
                //logSessionEvent("makeConnection() call publish()", "");
                //publish();
            } else {
                console.log("makeConnection done");
                //logSessionEvent("session.connect() done", "");
            }
        });
    },
    connectAndSendMessage: function (messageData, callBack, source) {
        this.connect();
        this._signalMessage = messageData;
        this._signalCallBack = callBack;
        this._signalMessageSource = source;
    },
    handleSignalCallback: function () {
        if (typeof this._signalCallBack === "function") {
            this._signalCallBack();
            delete this._signalCallBack;
        }
    },
    canSignal: function () {
        if (this._signalMessage) {
            // signal
            this.sendMessage(this._signalMessage);
            // clear
            delete this._signalMessage;
        }
    },
    sendMessage: function (messageData) {
        /*
        * how could we get the recipientConnection ?
        * this._session.connection is THIS user's connection object
        * "You can also obtain the Connection object corresponding to a stream by getting the connection property of the Stream object."
        * so, where is the stream? (of the other user)
        */
        var _this = this;
        console.log("toOpentokSessionHandler.sendMessage", messageData);
        // messageData.to is an user id here
        var signalData = {
            type: messageData.type,
            data: JSON.stringify(messageData),
            /* to: recipientConnection // a Connection object */
        };
        if (this._otherConnection) {
            //signalData.to = this._otherConnection;
        }
        if (this._session) {
            this._session.signal(signalData, function (error) {
                if (error) {
                    console.log("signal error: " + error.message);
                } else {
                    console.log("signal sent");
                }
                _this.handleSignalCallback();
            });
        } else {
            console.log("toOpentokSessionHandler.sendMessage - ERROR: session not found");
        }
    },
};
toOpentokSessionHandler.init();



var toCallHandler = {
    init: function (params) {
        console.log("toCallHandler.init()");
        this.alertModalId = params.alert;
        this.callbackModalSelector = "#to-alert-callback";
        this.callAudioElement = document.getElementById(params.audioElementId);
        this._processing = false;
        this._soundAllowed = false;
        this._maxWait = 60000; // max ms to try outgoing call
        this._updateInterval = 2000;  // frequency to check status change of outgoing call
        this._alertTypes = { INCOMING: "incoming", OUTGOING: "outgoing" };
        this._defaultSideModalWidth = $("#" + this.alertModalId).width();
        // handlers
        this.initEventListeners();
        // texts
        this.initTexts();
        // browser notification
        this.initNotifications();
    },
    initTexts: function () {
        this.texts = {
            incomingAccept:     Lang.get('tours.connecting'),
            incomingInstant:    "", // XY is calling "to talk" ? "for your assistane" ? ... for now just empty str
            incomingTour:       Lang.get('tours.is_calling'),
            outgoingCall:       Lang.get('tours.calling'),
            outgoingBusy:       Lang.get('tours.busy'),
            outgoingCredits:    Lang.get('tours.not_enough_credit'),
            outgoingTimeout:    Lang.get('tours.try_again'), //nobr?
            outgoingDecline:    Lang.get('tours.call_declined'),
            outgoingCancel:     Lang.get('tours.call_cancelled'),
            outgoingAccept:     Lang.get('tours.connecting'),
            outgoingReceived:   Lang.get('tours.message_from'),
            free:               Lang.get('tours.free'),
            free_call:          Lang.get('tours.free_call'),
        };
    },
    initNotifications: function () {
        if (theone.user == undefined) return;
        this.canNotify = toNotificationHandler.canNotify;
    },
    outgoingEnded: function () {
        this.stopRing();
        this.processing(false);
    },
    initEventListeners: function () {
        this.addAlertModalListeners();
        this.addCallbackModalListeners();
    },
    addCallbackModalListeners: function () {
        var _this = this;
        $(this.callbackModalSelector + " .btn-request").on("click", function (e) {
            //console.log("create callback request! " , _this._callBackData.guide_id);
            if (_this._callBackData && _this._callBackData.request_without_call) {
                _this.requestCallback();
            } else {
                _this.triggerMissedCall(true);
            }
        });
        $(this.callbackModalSelector + " .btn-cancel").on("click", function (e) {
            _this.triggerMissedCall(false);
            $(_this.callbackModalSelector).hide();
        });
        $(this.callbackModalSelector + " .btn-more").on("click", function (e) {
            $(this).toggleClass("opened");
            if ($(this).hasClass("opened")) {
                $(_this.callbackModalSelector + " .more-content").slideDown();
            } else {
                $(_this.callbackModalSelector + " .more-content").slideUp();
            }
        });
    },
    addAlertModalListeners: function () {
        var _this = this;
        $("#" + this.alertModalId + " .buttons-cancel .btn-cancel").on("click", function (e) {
            _this.cancelInstantCall();
        });
        $("#" + this.alertModalId + " .buttons-cancel .btn-topup").on("click", function (e) {
            location.href = theone.url("/" + theone.session.content_locale + "/settings/wallet");
        });
        $("#" + this.alertModalId + " .buttons-close .btn-cancel").on("click", function (e) {
            _this.closeOutgoingCall();
        });
        $("#" + this.alertModalId + " .decline-reason").on("click", function (e) {
            if (_this._processing) {
                return;
            }
            // decline with this text
            _this.declineInstantCall($(this).text());
        });
        $("#" + this.alertModalId + " .btn-accept").on("click", function (e) {
            if (_this._processing) {
                return;
            }
            _this.stopRing();
            _this.acceptInstantCall();
        });
        $("#" + this.alertModalId + " .btn-decline").on("click", function (e) {
            if (_this._processing) {
                return;
            }
            /*
            if (_this.incomingCallData && _this.incomingCallData.tour_id) {
                $("#" + _this.alertModalId + " .decline-reasons-tour").slideDown();
            } else {
                $("#" + _this.alertModalId + " .decline-reasons-instant").slideDown();
            }
            */
            _this.declineInstantCall(_this.texts.outgoingDecline);
        });
        $("#" + this.alertModalId + " .more-button").on("click", function (e) {
            $(this).toggleClass("open");
            $("#" + _this.alertModalId + " .more-options").toggle();
        });
        $("#" + this.alertModalId + " .btn-decline-block").on("click", function (e) {
            if (_this._processing) {
                return;
            }
            _this.declineByBlock();
        });
        $("#" + this.alertModalId + " .btn-callback").on("click", function (e) {
            _this.showCallBackRequest();
        });
    },
    declineByBlock: function () {
        this.declinedBecauseBlocking = true;
        this.declineInstantCall("Unavailable");
    },
    declineInstantCall: function (declineReason) {
        var _this = this;
        this.processing(true);
        this.stopRing();
        if (this.incomingCallData && this.incomingCallData.status === theone.App_InstantCall_STATUS_PLACED) {
            this.skipNextPing = true;
            this.incomingCallData.status = theone.App_InstantCall_STATUS_DECLINED;
            this.toLog("declineInstantCall", { call_id: this.incomingCallData.incoming_call_id });
            // send decline to opentok - needed for the app
            this.opentokSessionDecline(declineReason);
            // send decline to server
            $.ajax({
                url: "/api/decline-call",
                type: "POST",
                data: {
                    callId: _this.incomingCallData.incoming_call_id,
                    reason: declineReason,
                    block: _this.declinedBecauseBlocking ? "block" : null,
                }
            }).done(function (response) {
                console.log("call declined: " , response);
                _this.skipNextPing = true;
            }).fail(function (xhr, status, msg) {
                // something went wrong
            }).always(function () {
                _this.hideSideModal(_this.alertModalId);
                _this.declinedBecauseBlocking = false;
                delete _this.incomingCallData;
            });
        }
    },
    acceptInstantCall: function () {
        var _this = this;
        if (this.incomingCallData && this.incomingCallData.status === theone.App_InstantCall_STATUS_PLACED) {
            this.processing(true);
            this.toLog("acceptInstantCall", { call_id: this.incomingCallData.incoming_call_id });
            //var acceptData = { firebase_token: null, firebase_url: null, guide_id: null, message: null };
            this.firebaseAcceptCall(this.incomingCallData);
            //if (this.incomingCallData.source === theone.App_InstantCall_SOURCE_APP && this.hasOpentokHandler()) {
            // send the opentok message even if the source is web - the user might have the app too and that need to know if accepted
            // -> the difference is, if the source is app, the signal should wait for another connection
            if (this.hasOpentokHandler()) {
                this.opentokSessionAccept(function () {
                    _this.redirectToAccept();
                });
            } else {
                this.redirectToAccept();
            }
        }
    },
    redirectToAccept: function () {
        this.incomingCallData.status = theone.App_InstantCall_STATUS_ACCEPTED;
        //this.modalText( { modalId: this.incomingModalId, txt: this.texts.incomingAccept } );
        document.location.href = theone.url("/" + theone.session.content_locale + "/virtual-tour/accept/" + this.incomingCallData.incoming_call_id);
    },
    processing: function (isProcessing) {
        //console.log("processing", isProcessing, $("#call-processing").length);
        if (isProcessing) {
            this._processing = true;
            $("#" + this.alertModalId + " .decline-reasons-tour").hide();
            $("#" + this.alertModalId + " .decline-reasons-instant").hide();
            $("#call-processing").show();
        } else {
            this._processing = false;
            $("#call-processing").hide();
        }
    },
    hasOpentokHandler: function () {
        return (typeof toOpentokSessionHandler !== "undefined");
    },
    firebaseAcceptCall: function (acceptData) {
        console.log("toCallHandler.firebaseAcceptCall", acceptData);
        var notification = {
            to: acceptData.guide_id,
            message: Lang.get('tours.picked_up'),
            payload: {
                type: toOpentokSessionHandler.MESSAGE_TYPE.ACCEPT,
            },
        };
        this.callFirebaseCloudFunction(acceptData.firebase_token, acceptData.firebase_url, notification);
    },
    firebaseCancelCall: function (cancelData) {
        console.log("toCallHandler.firebaseCancelCall", cancelData);
        var notification = {
            to: cancelData.guide_id,
            message: cancelData.message,
            payload: {
                type: toOpentokSessionHandler.MESSAGE_TYPE.CANCEL,
            },
        };
        this.callFirebaseCloudFunction(cancelData.firebase_token, cancelData.firebase_url, notification);
    },
    firebaseSendCall: function () {
        //console.log("toCallHandler.firebaseSendCall", this.placedCallData);
        var notification = {
            callerId: this.placedCallData.caller_id,
            calleeId: this.placedCallData.guide_id,
            sessionId: this.placedCallData.session_id,
            caller: null,
            proposedMessage: this.placedCallData.call_message,
            env: location.hostname,
        };
        this.callFirebaseCloudFunction(this.placedCallData.firebase_token, this.placedCallData.firebase_url, notification);
    },
    callFirebaseCloudFunction: function (firebase_token, notifurl, notification) {
        var _this = this;
        console.log("callFirebaseCloudFunction", notifurl);
        try {
            var params = {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(notification)
            };
            if (typeof firebase !== "undefined") {
                firebase.auth().signInAndRetrieveDataWithCustomToken(firebase_token);
                fetch(notifurl, params).then(function(response) {
                    //_this.toLog("callFirebaseCloudFunction fetch done");
                    // response is a Fetch API Response object - get actual response 
                    response.json().then(function (jsonData) {
                        // jsonData is defined by the firebase cloud function
                        // so what the exported sendCallNotification sends back
                        // example: {callNotificationResult: "fcm notification sent"}
                        //_this.toLog("callFirebaseCloudFunction JSON", { jsonData: jsonData });
                        if (jsonData && jsonData.callNotificationResult) {
                            // if the key callNotificationResult is there - we know push was sent
                            // [voip DEV notification sent, voip notification sent, fcm notification sent]
                            var tmp = jsonData.callNotificationResult.split(" ");
                            var notificationType = String(tmp[0]).toLowerCase();
                            if (notificationType === "fcm") {
                                _this.saveNotificationStatus(theone.App_InstantCall_PUSH_STATUS_FCM, _this.placedCallData.session_id);
                            } else if (notificationType === "voip") {
                                _this.saveNotificationStatus(theone.App_InstantCall_PUSH_STATUS_VOIP, _this.placedCallData.session_id);
                            } else {
                                _this.saveNotificationStatus(theone.App_InstantCall_PUSH_STATUS_SENT, _this.placedCallData.session_id);
                            }
                        }
                    });
                })
                .catch(function(error) {
                    _this.toLog("callFirebaseCloudFunction fetch error", error);
                });
            } else {
                _this.toLog("callFirebaseCloudFunction ERROR firebase not found");
            }
        } catch (e) {
            _this.toLog("callFirebaseCloudFunction ERROR exception", e);
        }
    },
    opentokSessionCall: function () {
        //this.opentokSessionMessage(this.placedCallData, toOpentokSessionHandler.MESSAGE_TYPE.ACCEPT, "accept-call");
    },
    opentokSessionDecline: function (declineReason) {
        var declineType = toOpentokSessionHandler.MESSAGE_TYPE.DECLINE;
        if (declineReason !== this.texts.outgoingDecline) {
            // user selected a decline message
            declineType = toOpentokSessionHandler.MESSAGE_TYPE.LATER;
        }
        this.hasOpentokHandler() && this.opentokSessionMessage(this.incomingCallData, declineType, declineReason);
    },
    opentokSessionAccept: function (callBack) {
        this.opentokSessionMessage(this.incomingCallData, toOpentokSessionHandler.MESSAGE_TYPE.ACCEPT, "accept-call", callBack);
    },
    opentokSessionCancel: function (callBack) {
        if (this.placedCallData) {
            // opentokSessionMessage input dataSource is based on incomingCallData
            // -> expects guide_id as sender and caller_id as receiver
            //    placedCallData has the other way around, so make a new object for the message
            var fixedSource = Object.assign({}, this.placedCallData);
            fixedSource.guide_id = this.placedCallData.caller_id;
            fixedSource.caller_id = this.placedCallData.guide_id;
            this.opentokSessionMessage(fixedSource, toOpentokSessionHandler.MESSAGE_TYPE.CANCEL, "cancel-call", callBack);
            /* // below would be if initSessionData was already called
            toOpentokSessionHandler.sendMessage({
                type: toOpentokSessionHandler.MESSAGE_TYPE.CANCEL,
                message: "cancel-call",
                from: this.placedCallData.caller_id,
                to: this.placedCallData.guide_id,
                sessionId: this.placedCallData.session_id,
                device: this.hashStr(navigator.userAgent)
            });
            */
        } else {
            console.log("could not send opentok cancel, call was already ended");
        }
    },
    opentokSessionMessage: function (dataSource, messageType, messageString, callBack) {
        // messaging through opentok - needed for the app
        console.log("toOpentokSessionHandler init and send", dataSource.session_id);
        toOpentokSessionHandler.initSessionData(dataSource.session_id, dataSource.session_api_key, dataSource.session_token);
        toOpentokSessionHandler.connectAndSendMessage({
            type: messageType,
            message: messageString,
            from: dataSource.guide_id,
            to: dataSource.caller_id,
            device: this.hashStr(navigator.userAgent)
        }, callBack, dataSource.source);
    },
    saveNotificationStatus: function (status, sessionId) {
        var postData = { notification_status: status, session_id: sessionId };
        $.ajax({
            url: "/api/notification-status",
            type: "POST",
            data: postData
        }).done(function (msg) {
            //console.log(msg);
        });
    },
    hashStr: function (str) {
        var hash = 0, i, chr;
        if (!str) return hash;
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return "#" + ((hash + 2147483647) + 1) + "#";
    },
    toLog: function (action, data) {
        // source, deviceId, action, data{}
        var postData = { source: "WEB", deviceId: this.hashStr(navigator.userAgent), action:action, data:data };
        $.ajax({
            url: "/api/app-log",
            type: "POST",
            contentType: "application/json",
            data: JSON.stringify(postData)
        }).done(function (msg) {
            //console.log(msg);
        });
    },
    toPing: function () {
        console.log("toPing", this.skipNextPing);
        var pingData = { reason: "polling" };
        var _this = this;
        if (this.skipNextPing) { //  || this._callInitiated - no, the incoming call is updated bu the ping too
            this.skipNextPing = false;
            this.startPolling();
            return;
        }
        if (this.incomingCallData && this.incomingCallData.status === theone.App_InstantCall_STATUS_PLACED) {
            pingData = {
                reason: "updateStatus",
                callId: this.incomingCallData.incoming_call_id
            };
        }
        $.ajax({
            url: "/api/ping",
            type: "POST",
            data: pingData
        }).done(function (msg) {
            //console.log("pinged: " , msg);
            if (msg.success && !_this.skipNextPing) { // check for skipNextPing, the user might have declined while ping ajax was waiting for response
                if (msg.incoming_call_id) {
                    // incoming call, is this already shown or new?
                    if (!_this.incomingCallData) {
                        //console.log("NEW CALL", msg, msg.session_id);
                        _this.incomingCallData = msg;
                        if (msg.tour_id) {
                            // this is not an instant call but a call for an existing tour
                            //_this.modalText( { modalId: _this.incomingModalId, txt: _this.texts.incomingTour } );
                        } else {
                            //_this.modalText( { modalId: _this.incomingModalId, txt: _this.texts.incomingInstant } );
                        }
                        _this.toLog("toPing / received new call", { call_id: _this.incomingCallData.incoming_call_id });
                        _this.declinedBecauseBlocking = false; // just to make sure
                        _this.launchSideModal(msg, _this.alertModalId, _this._alertTypes.INCOMING);
                        _this.startRing();
                        toNotificationHandler.notify(Lang.get('tours.incoming_call_from', {name: msg.caller_name }),Lang.get('tours.incoming_call_answer'));

                    } else {
                        // the incoming call was already registered, still the status might have changed (eg: was accepted in the app)
                        if (msg.status !== theone.App_InstantCall_STATUS_PLACED) {
                            toNotificationHandler.stopNotifying();
                            _this.stopRing();
                            _this.hideSideModal(_this.alertModalId);
                            delete _this.incomingCallData;
                        } else {
                            // the session id possibly changed, when app creates a new one (shouldn't that be prevented?? TODO CHECK)
                            _this.incomingCallData.session_id = msg.session_id;
                        }
                    }
                }
                if (msg.chat_data) {
                    if (typeof toChatHandler !== "undefined") {
                        toChatHandler.processUnreadData(msg.chat_data);
                    }
                }
            }
            _this.startPolling();
        }).fail(function (xhr, status, msg) {
            // something went wrong
            // can possibly be a temp network failure? try again?
            _this.startPolling();
        });
    },
    startPolling: function () {

        // var DISABLE_PING = Config.get('app.disable_ping');
        // if (DISABLE_PING) return;

        var _this = this;
        this.toPollingTimeoutID = setTimeout(function () {
            _this.toPing();
        }, 10 * 1000);
    },
    stopPolling: function () {
        if (this.toPollingTimeoutID) {
            clearTimeout(this.toPollingTimeoutID);
            delete this.toPollingTimeoutID;
        }
    },
    stopRing: function () {
        this._callInitiated = false; // either incoming or outgoing call was handled in some way
        this.callAudioElement.pause();
    },
    startRing: function () {
        var _this = this;
        console.log("toOpentokSessionHandler.startRing", this._soundAllowed);
        _this._callInitiated = true; // either incoming or outgoing call is happening
        if (_this._soundAllowed) {
            try {
                _this.callAudioElement.currentTime = 0;
                var result = _this.callAudioElement.play();
                if (result !== undefined) {
                    result.then(function () {
                        _this.callAudioElement.muted = false;
                    }).catch(function (error) {
                        console.log("Browser not allowed to play sound without interaction");
                    });
                } else {
                    _this.callAudioElement.muted = false;
                }
            } catch (e) {
                console.log("Could not start audio: " , e);
            }
        }
    },
    placeOutgoingCall: function (params) {
        var _this = this;
        if (toCallPermissionsHelper.needPermissionsCheck()) {
            _this.checkPermissionsBeforeCall(params);
        } else {
            toCallPermissionsHelper.silentPermissionCheck(function () {
                _this.doCall(params);
            },
            function (error) {
                console.log("silentPermissionCheck failed", error);
                // when denied (chrome)
                if (error && error.result && error.result.state === "denied") {
                    // checkCallPermissions - just doublecheck + it will show the correct error msg
                    toCallPermissionsHelper.checkCallPermissions(function () {
                        _this.doCall(params);
                    });
                }
            },
            function () {
                _this.checkPermissionsBeforeCall(params);
            });
        }
    },
    checkPermissionsBeforeCall: function (params) {
        var _this = this;
        toModalHelper.showAlert(
            Lang.get('tours.check_before_call'),
            Lang.get('tours.check_before_call_title'),
            function () {
                toCallPermissionsHelper.checkCallPermissions(function () {
                    _this.doCall(params);
                });
            }
        );
    },
    doCall: function (params) {
        //console.log("make instant call to " , params);
        var _this = this;
        var ajaxData = { calledUser: params.userId };
        if (params.tourProposalId) {
            ajaxData.tourProposalId = params.tourProposalId;
        }
        if (params.promo) {
            ajaxData.promo = params.promo;
        }
        $(this.callbackModalSelector + " .call-info").html(params.callbackText ? params.callbackText : "");
        this._callBackData = null;
        this.calledUserName = params.userName;
        this.launchSideModal({
            caller_img: params.userImg,
            caller_first_name: params.firstName,
            caller_last_name: params.lastName,
            caller_profession: params.profession,
            caller_location: params.location,
            caller_tagline: params.tagline,
            caller_rating: params.rating ? Number(params.rating) : 0,
        }, this.alertModalId, this._alertTypes.OUTGOING);
        this.startRing();
        this.toLog("placeOutgoingCall", ajaxData);
        $.ajax({
            url: "/api/instant-call",
            type: "POST",
            data: ajaxData
        }).done(function (response) {
            if (response.success) {
                //console.log("call placed: " , response);
                _this.placedCallData = response;
                _this.placedCallData.waitingTime = 0;
                // do the firebase notification here, session is now created
                _this.firebaseSendCall();
                // polling to sign still calling? yeah and also to see if it was picked up.
                _this.updateInstantCall();
            } else {
                console.log("call denied", response);
                _this.outgoingEnded();
                if (response.reason === theone.App_InstantCall_STATUS_BUSY) {
                    if (response.callback_title) {
                        _this._callBackData = {
                            callback_title: response.callback_title,
                            guide_id: ajaxData.calledUser,
                        };
                    }
                    if (response.call_id) {
                        _this.placedCallData = { call_id: response.call_id };
                    }
                    _this.showSideModalMessage(_this.alertModalId, _this.texts.outgoingBusy);
                    // show close / callback button for this case too
                    _this.toggleModalCancelClose(_this.alertModalId, true);
                } else if (response.reason === "blocked") {
                    // just show as busy
                    _this.showSideModalMessage(_this.alertModalId, _this.texts.outgoingBusy);
                } else if (response.reason === "credits") {
                    _this.showSideModalMessage(_this.alertModalId, _this.texts.outgoingCredits);
                    $(".to-alert-type-outgoing .buttons-cancel .btn-topup").show();
                }
            }
        }).fail(function (xhr, status, msg) {
            // something went wrong
        }).always(function () {
            _this.outgoingCallInitialized();
        });
    },
    outgoingCallInitialized: function () {
        // hide loader
        $("#call-outgoing-processing").hide();
        // show cancel button
        $(".to-alert-type-outgoing .buttons-cancel .btn-cancel").show();
    },
    updateInstantCall: function () { // updating the status of an outgoing call - accepted / declined / timed out
        var _this = this;
        if (!this.placedCallData) {
            return;
        }
        this.placedCallData.timeoutID = setTimeout(function () {
            _this.placedCallData.waitingTime += _this._updateInterval;
            if (_this.placedCallData.waitingTime >= _this._maxWait) {
                if (_this.placedCallData.status !== theone.App_TourProposal_STATUS_CALLBACK) {
                    // we should have the callBackData here, before showSideModalMessage
                    _this._callBackData = {
                        callback_title: Lang.get('tours.callback_ask', {'name' : ''}), // temp title, will be updated
                        guide_id: _this.placedCallData.guide_id,
                    };
                }
                _this.placedCallData.status = theone.App_InstantCall_STATUS_TIMEOUT;
                _this.showSideModalMessage(_this.alertModalId, _this.texts.outgoingTimeout);
                _this.showOutgoingTitle(_this.alertModalId, "noanswer");
                _this.toggleModalCancelClose(_this.alertModalId, true);
                _this.outgoingEnded();
                _this.toLog("updateInstantCall / timeout", { call_id: _this.placedCallData.call_id });
                // this status needs to be saved!
                $.ajax({
                    url: "/api/timeout-call",
                    type: "POST",
                    data: {
                        callId: _this.placedCallData.call_id
                    }
                }).done(function (response) {
                    console.log("call timed out and updated: " , response);
                    if (response.callback_title) {
                        // callback request is possible
                        _this._callBackData.callback_title = response.callback_title;
                        // maybe the popover was already shown, update the title
                        $(_this.callbackModalSelector + " .call-message").html(response.callback_title);
                        // also send firebase cancel
                        _this.firebaseCancelCall(response);
                        // also in opentok
                        _this.opentokSessionCancel();
                    } else {
                        delete _this._callBackData;
                        // to make sure, maybe user already opened callback
                        $("#" + _this.alertModalId + " .btn-callback").hide();
                        $(_this.callbackModalSelector).hide();
                    }
                }).fail(function (xhr, status, msg) {
                    console.log("timout call failed", status, msg);
                    // something went wrong
                });
                return;
            }
            $.ajax({
                url: "/api/check-call",
                type: "POST",
                data: {
                    callId: _this.placedCallData.call_id
                }
            }).done(function (response) {
                //console.log("call updated: " , response);
                if (response.success) {
                    if (_this.placedCallData) {
                        _this.placedCallData.status = response.status; // do this to make cancelInstantCall do as expected
                    }
                    if (response.status === theone.App_InstantCall_STATUS_DECLINED) {
                        // show some notification
                        var declineMessage = response.message ? response.message : _this.texts.outgoingDecline;
                        if (response.callback_title) {
                            // callback request is possible
                            _this._callBackData = response;
                            _this._callBackData.guide_id = _this.placedCallData.guide_id;
                        }
                        _this.showSideModalMessage(_this.alertModalId, declineMessage);
                        _this.toggleModalCancelClose(_this.alertModalId, true);
                        _this.showOutgoingTitle(_this.alertModalId, "declined");
                        _this.outgoingEnded();
                        _this.toLog("updateInstantCall / callee declined", { call_id: response.call_id });
                    } else if (response.status === theone.App_InstantCall_STATUS_PLACED) {
                        // keep calling
                        _this.updateInstantCall();
                    } else if (response.status === theone.App_InstantCall_STATUS_ACCEPTED) {
                        // redirect to the call
                        _this.showSideModalMessage(_this.alertModalId, _this.texts.outgoingAccept);
                        _this.stopRing();
                        _this.toLog("updateInstantCall / callee accepted", { call_id: response.call_id });
                        document.location.href = theone.url("/" + theone.session.content_locale + "/virtual-tour/join/" + response.room_id + "/" + response.call_id + "/" + _this.calledUserName);
                    }
                }
            }).fail(function (xhr, status, msg) {
                // something went wrong
            });
        }, this._updateInterval);
    },
    closeOutgoingCall: function () {
        this.outgoingEnded();
        this.triggerMissedCall(false);
        if (this.placedCallData) {
            delete this.placedCallData;
        }
        this.hideSideModal(this.alertModalId);
    },
    triggerMissedCall: function (callbackRequested) {
        var _this = this;
        if (this.placedCallData) {
            var postData = { callId: this.placedCallData.call_id };
            if (callbackRequested) {
                postData.callbackRequested = true; // only add the prop if callback was requested
            }
            $.ajax({
                url: "/api/action-after-missed-call",
                type: "POST",
                data: postData
            }).done(function (response) {
                //console.log("triggerMissedCall DONE: " , response);
                if (response.success) {
                    if (callbackRequested) {
                        _this.requestCallback();
                    }
                }
            }).fail(function (xhr, status, msg) {
                // something went wrong
                console.log("triggerMissedCall ERROR: " , msg);
            });
        }
    },
    requestCallback: function () {
        if (this._callBackData) {
            document.location.href = theone.url("/" + theone.session.content_locale + "/request-callback/" + this._callBackData.guide_id);
        }
    },
    cancelInstantCall: function () {
        var _this = this;
        this.outgoingEnded();
        this.showOutgoingTitle(this.alertModalId, "cancelled"); // "cancelled" does not exists now, so will just hide
        if (this.placedCallData) {
            this.toLog("cancelInstantCall", { call_id: this.placedCallData.call_id });
            if (this.placedCallData.status === theone.App_InstantCall_STATUS_PLACED) {
                $.ajax({
                    url: "/api/cancel-call",
                    type: "POST",
                    data: {
                        callId: this.placedCallData.call_id
                    }
                }).done(function (response) {
                    console.log("call cancelled: " , response);
                    if (response.callback_title) {
                        // callback request is possible
                        _this._callBackData = response;
                        _this._callBackData.guide_id = _this.placedCallData.guide_id;
                    }
                    _this.showSideModalMessage(_this.alertModalId, _this.texts.outgoingCancel);
                    var showModalClose = response.promo != undefined ? false : true;
                    _this.toggleModalCancelClose(_this.alertModalId, showModalClose);
                    _this.firebaseCancelCall(response);
                    _this.opentokSessionCancel();
                }).fail(function (xhr, status, msg) {
                    // something went wrong
                }).always(function () {
                    //delete _this.placedCallData; // don't do this here, needed still in triggerMissedCall
                });
            }
            if (this.placedCallData.timeoutID) {
                clearTimeout(this.placedCallData.timeoutID);
            }
        } else {
            this.hideSideModal(this.alertModalId);
        }
    },
    hideSideModal: function (modalId) {
        var modalElement = $("#" + modalId);
        var modalWidth = modalElement.width();
        if (modalWidth === this._defaultSideModalWidth) {
            modalElement.css("right", "-" + modalWidth + "px");
            setTimeout(function () {
                // this should be in a transitionend event handler, not timeout
                modalElement.hide();
            }, 200);
        } else {
            // not the "normal" side modal, fullsize
            modalElement.hide();
            modalElement.width(this._defaultSideModalWidth);
            modalElement.css("right", "-" + this._defaultSideModalWidth + "px");
        }
        this.processing(false);
    },
    showSideModalMessage: function (modalId, msg) {
        $("#" + modalId + " .call-info").hide();
        $("#" + modalId + " .call-message").html(msg).show();
    },
    showOutgoingTitle: function (modalId, variant) {
        $("#" + modalId + " .call-title-text").hide();
        $("#call-title-" + variant).show();
    },
    toggleModalCancelClose: function (modalId, showClose) {
        if (showClose) {
            $("#" + modalId + " .buttons-cancel").hide();
            $("#" + modalId + " .buttons-close").show();
            if (this._callBackData) {
                $("#" + modalId + " .btn-callback").show();
                $(this.callbackModalSelector + " .call-message").html(this._callBackData.callback_title);
            } else {
                $("#" + modalId + " .btn-callback").hide();
            }
        } else {
            $("#" + modalId + " .buttons-cancel").show();
            $("#" + modalId + " .buttons-cancel .btn-topup").hide();
            $("#" + modalId + " .buttons-close").hide();
        }
    },
    resetSideModal: function (modalId) {
        //$("#" + modalId).height(window.innerHeight);
        $("#" + modalId + " .call-title-text").hide();
        $("#" + modalId + " .call-info").show();
        $("#" + modalId + " .call-message").hide();
        $("#" + modalId + " .decline-reasons-tour").hide();
        $("#" + modalId + " .decline-reasons-instant").hide();
        $("#" + modalId + " .more-button").removeClass("open");
        $("#" + modalId + " .more-options").hide();
        this.toggleModalCancelClose(modalId, false);
        // hide all type specific contents
        $("#" + modalId).find(".to-alert-typespec").hide();
    },
    launchSideModal: function (callData, modalId, alertType) {
        var selector = "#" + modalId;
        this.resetSideModal(modalId);
        // show the relevant type specific contents
        $(selector).find(".to-alert-type-" + alertType).css("display", "block");
        if (alertType === this._alertTypes.OUTGOING) {
            $("#call-title-outgoing").show();
            $(selector).css("width", "100%");
            // show loader
            $("#call-outgoing-processing").show();
            // hide cancel button while processing
            $(selector + " .buttons-cancel .btn").hide();
        } else {
            // incoming - should also check for tour_status / callback
            if (callData.tour_status === theone.App_TourProposal_STATUS_CALLBACK) {
                // this is a call made for a callback request - we need the rate (price per minute) of the caller
                // -> callData.caller_cost_per_minute
                if (callData.caller_cost_free) {
                    $("#call-info-price-text").html(String(this.texts.free).toUpperCase());
                    $(selector + " .call-info-price-label").hide(); // the "PM"
                    $(selector + " .call-callback-subtitle").hide(); // the "you accept the costs"
                } else {
                    // host is not free, but user maybe still in free intro time?
                    if (callData.callback_free_remaining) {
                        // don't show the warning about accepting the costs
                        $(selector + " .call-callback-subtitle").hide();
                        // show free in big cost box (ppm will be in text below, included in callback_free_remaining)
                        $("#call-info-price-text").html(String(this.texts.free_call).toUpperCase());
                        $(selector + " .call-info-price-label").hide(); // the "PM"
                        $(selector + " .call-callback-subtitle").hide();// the "you accept the costs"
                        // and show the info about the free time remaining (append to callback_text)
                        callData.callback_text += " " + callData.callback_free_remaining;
                    } else {
                        // no, user will have to pay
                        $("#call-info-price-text").html(callData.caller_cost_per_minute);
                        $(selector + " .call-info-price-label").show();
                        $(selector + " .call-callback-subtitle").show();
                    }
                }
                $("#call-title-callback").show();
                $("#call-title-incoming").hide();
                $(selector + " .call-callback-title").html(callData.callback_text);
                $(selector + " .call-callback").show();
                $(selector + " .call-rating").hide();
                callData.caller_tagline = null; // don't show tagline when callback
                $(selector + " .call-more").hide();
            } else {
                // incoming instant call
                $("#call-title-callback").hide();
                $("#call-title-incoming").show();
                $(selector + " .call-more").show();
                $(selector + " .call-rating").show();
                $(selector + " .call-callback").hide();
                if (callData.tour_id) {
                    $(selector + " .decline-reasons-tour").show();
                } else {
                    $(selector + " .decline-reasons-instant").show();
                }
            }
            $(selector).width(this._defaultSideModalWidth);
        }
        // define variable contents
        if (callData.caller_img) {
            $(selector + " .call-avatar-img").css("background-image", "url('" + callData.caller_img + "')").show();
            $(selector + " .call-avatar-img .placeholder").hide();
        } else {
            $(selector + " .call-avatar-img").hide();
            $(selector + " .call-avatar-img .placeholder").show();
        }
        // fill in: $(selector + " .call-name") , .call-info-profession , .call-info-location , .tagline-user
        $(selector + " .call-name").html(callData.caller_first_name + "<br>" + callData.caller_last_name);
        $(selector + " .call-info-profession").html(callData.caller_profession ? callData.caller_profession : "");
        $(selector + " .call-info-location").html(callData.caller_location);
        if (callData.caller_tagline) {
            $(selector + " .call-tagline").show();
            $(selector + " .tagline-user").html(callData.caller_tagline);
        } else {
            $(selector + " .call-tagline").hide();
        }
        $(selector + " #call-rating-average").data("rating-value", callData.caller_rating);
        $(selector + " #call-rating-average").rateYo("rating", callData.caller_rating);
        // and show the modal
        $(selector).show().css("right", "0px");
    },
    showCallBackRequestWitData: function (userName, userId) {
        this._callBackData = {
            callback_title: Lang.get("tours.callback_ask", { name: userName }),
            guide_id: userId,
            request_without_call: true,
        };
        $(this.callbackModalSelector + " .call-message").html(this._callBackData.callback_title);
        this.showCallBackRequest();
    },
    showCallBackRequest: function () {
        this.hideSideModal(this.alertModalId);
        // reset the callback modal?
        //$(this.callbackModalSelector).css("width", "100%");
        $(this.callbackModalSelector).show().css("right", "0px");
    },
    /* ----- */
    testOut: function () {
        //...
        var params = {
            caller_first_name: "Test",
            caller_last_name: "Godfrey",
            caller_profession: "Coach",
            caller_location: "Amsterdam",
            caller_rating: 8,
            caller_tagline: "Foobar!",
        };
        this.launchSideModal(params, this.alertModalId, this._alertTypes.OUTGOING);
        this.showSideModalMessage(this.alertModalId, this.texts.outgoingTimeout);
        this.showOutgoingTitle(this.alertModalId, "noanswer");
        this.toggleModalCancelClose(this.alertModalId, true);
    },
};

$(document).ready(function () {

    var modalData = {};
    modalData.alert = "to-alert-call";
    modalData.audioElementId = "theone-call-ringtone";
    toCallHandler.init(modalData);

    if (theone.user && theone.user.allowSiteCalls) {
        // start polling - but not always
        toCallHandler._soundAllowed = theone.user.allowSiteCallSound;
        if (theone.webinar_call) {
            // do not do polling during webinar call
        } else {
            toCallHandler.startPolling();
        }
    } 

});

